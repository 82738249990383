import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarioComponent } from './calendario/calendario.component';
import { ComponentsModule } from 'src/app/components/components.module';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarioParametriComponent } from './calendario-parametri/calendario-parametri.component';
import { CalendarioSchedaComponent } from './calendario-scheda/calendario-scheda.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [
    CalendarioComponent,
    CalendarioParametriComponent,
    CalendarioSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FullCalendarModule
  ]
})
export class CalendarioModule { }
