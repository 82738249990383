import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ParametriCalendario } from '../common-items/parametri-calendario';
import { Parametri } from '../common-items/parametri';
import { MacroCalendario } from '../common-items/macro-calendario';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {
  linkBase: string = "calendario";
  apiUrl: string;
  arrayCampiParametri: any[any];
  arrayCampiCalendario: any[any];
  arrayCampi: any[any];

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/' + this.linkBase + '/';

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'denominazione' : {'id' : 'denominazione', 'nome': 'Denominazione', 'required': true},
      'descrizione' : {'id' : 'descrizione', 'nome': 'Descrizione', 'type': 'TXA'},
      'idPercorso' : {'id' : 'idPercorso', 'nome': 'Percorso', 'type': 'NUM', 'required': true},
      'prezzo' : {'id' : 'prezzo', 'nome': 'Prezzo iniziale', 'type': 'VLT', 'required': true},
      'attivo' : {'id' : 'attivo', 'nome': 'Attivo subito', 'type': 'CHK', 'required': false},
    };

    this.arrayCampiParametri = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'capienza' : {'id' : 'capienza', 'nome': 'Capienza massima', 'required': true, 'note': 'Indica la quantità di default di titoli di ingresso vendibili per ogni singola fascia (indipendentemente dalla tipologia del titolo di ingresso)'},
      'durata' : {'id' : 'durata', 'nome': 'Durata', 'type': 'TIME', 'required': true, 'note': 'Indica la durata di default di ogni fascia che viene creata'},
    };

    this.arrayCampiCalendario = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'operazione' : {'id' : 'operazione', 'nome': 'Tipologia operazione', 'items': {1: 'Inserisci (o aggiorna) fasce', 0:'Cancella fasce esistenti'}, 'type': 'RAD', 'required': true, 'note': 'Nel caso di inserimento nuove fasce, eventuali fasce esistenti con stessa data ed orario verranno sostituite dai nuovi valori','inline' : true},
      'periodo' : {'id' : 'periodo', 'nome': 'Periodo', 'type': 'PER', 'minToday': true, 'required': true},
      'giorni' : {'id' : 'giorni', 'nome': 'Giorni della settimana', 'type': 'SLM', 'multiple': true, 'required': true},
      'tipologiaInserimento' : {'id' : 'tipologiaInserimento', 'nome': 'Metodo di inserimento', 'items': {1: 'Inserimento manuale degli orari', 2:'Creazione automatica degli orari'}, 'type': 'RAD', 'required': true, 'note': 'Nel caso di ripetizioni continue di orari, utilizzare la creazione automatica; se invece gli orari di inizio non seguono una regola, utilizzare inserimento manuale','inline' : true},
      'oraInizio' : {'id' : 'oraInizio', 'nome': 'Ora inizio prima fascia', 'type': 'ORA', 'required': true, 'note': 'Orario di inizio della prima fascia generata. Le successive inizieranno al termine delle precedenti, in base alla durata della fascia'},
      'oraFine' : {'id' : 'oraFine', 'nome': 'Ora fine ultima fascia', 'type': 'ORA', 'required': true, 'note': 'Massimo orario di fine dell\'ultima fascia generata (l\'ultima fascia potrenne non finire esattamente a questo orario, dipende dalla durata delle fasce) '},
      'durata' : {'id' : 'durata', 'nome': 'Durata fascia', 'type': 'ORA', 'required': true, 'note': 'Durata delle fasce generate. Ogni fascia successiva partirà al termine della precedente'},
      'esclusiva' : {'id' : 'esclusiva', 'nome': 'Visita esclusiva', 'items': {1: 'SI', 0:'NO'}, 'type': 'RAD', 'required': true, 'note': 'Nel caso di visita esclusiva, il cliente potrà scegliere il percorso da fare, e la fascia prenotata verrà marcata subito come non più disponibile ','inline' : true},
      'idPercorsi' : {'id' : 'idPercorsi', 'nome': 'Percorsi disponibili', 'type': 'SLM', 'multiple': true, 'required': true},
      'capienza' : {'id' : 'capienza', 'nome': 'Capienza massima', 'required': true, 'note': 'Indica la quantità massima di titoli di ingresso vendibili per ogni singola fascia (indipendentemente dalla tipologia del titolo di ingresso)'},
      'orariManuali' : {'id' : 'orariManuali', 'nome': 'Orari di inizio', 'required': true, 'note': 'Inserire gli orari di inizio delle fasce in formato \'hh:mm\', separati da \',\' (senza inserire spazi) '},
    };
  }

  getParametriCalendario(): Observable<ParametriCalendario> {
    return this.http.get<ParametriCalendario>(this.apiUrl + "op/parametriCalendario" )
  }

  getParametri(): Observable<Parametri> {
    return this.http.get<Parametri>(this.apiUrl + 'op/parametri')
  }

  sendParametri(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/parametri' , body);
  }

  getMacroCalendario(id: number): Observable<MacroCalendario> {
    return this.http.get<MacroCalendario>(this.apiUrl + "?op=macroCalendario&id=" + id)
  }

  sendMacroCalendario(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/macroCalendario' , body);
  }

}
